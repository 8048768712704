<template>
  <CCard class="mb-3">
    <CCardBody>
      <h1 class="display-4">{{ store.user.username }} profile</h1>
      Date joined: {{ formatedDate(store.user.date_joined) }}
      <CForm @submit.prevent="store.updateUserData(formData)">
        <BaseFormElement
          v-model="formData.first_name"
          label="First name"
          type="input"
        />
        <BaseFormElement
          v-model="formData.last_name"
          label="Last name"
          type="input"
        />
        <BaseFormElement v-model="formData.email" label="email" type="email" />
        <BaseButton
          class="float-end"
          color="info"
          text="Update info"
          type="submit"
        />
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script setup>
import { reactive } from "vue";
import { useMainStore } from "@/stores/mainStore.js";
import { formatedDate } from "@/common/utils.js";

const store = useMainStore();

const formData = reactive({
  first_name: store.user.first_name,
  last_name: store.user.last_name,
  email: store.user.email,
});
</script>
