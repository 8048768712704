// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const rehouseLogo = [
  "410 410",
  `
  <path d="M3.99258 162.104 162.097 130.5 405.303 162.52C410.379 166.886 408.78 172.292 404.886 172.5L162.097 140.896 6.49555 172.5C1.69818 168.619 1.69818 167.025 3.99258 162.104Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M15.6906 252.377 133.655 251.537 134.91 161.71 145.159 161.5 144.322 202.636 194.31 202.426 194.729 161.71 203.722 161.71 203.095 251.957 402.63 252.796C407.929 255.735 407.581 261.821 402.84 263.5L193.683 262.451 193.892 211.241 144.322 211.661 143.695 263.29 15.0632 262.87C10.88 258.323 12.3441 255.035 15.6906 252.377Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M80.5 178.131 80.5 239.101C91.3996 239.171 85.6413 240.082 113.404 238.891L112.376 229.85C109.154 231.743 104.287 232.373 89.7543 231.743L89.7543 210.718 110.731 210.508 111.142 201.047C107.098 203.009 96.8836 202.449 90.1657 203.15L90.577 186.33C90.5084 186.4 97.2264 182.055 112.582 186.33 115.529 181.985 114.775 179.112 112.376 177.5L80.5 178.131Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M240.157 185.239C238.109 185.118 235.955 185.568 233.224 186.434 218.868 198.346 226.151 227.086 231.753 231.518 237.356 235.466 241.698 234.843 245.409 230.895 255.774 220.368 253.743 190.52 246.46 187.473 244.149 186.053 242.206 185.361 240.157 185.239ZM239.449 177.525C242.655 177.682 245.696 178.578 249.313 180.414 260.711 184.356 266.83 222.137 250.61 235.755 244.802 240.861 235.065 242.499 226.298 237.392 217.53 231.658 206.133 194.48 228.599 179.07 232.874 177.951 236.244 177.368 239.449 177.525Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M39.547 184.753C37.9788 184.864 36.2297 185.13 34.2792 185.575L34.4872 209.704C46.9019 210.053 52.2423 203.899 53.0052 196.276 52.641 190.768 50.5246 183.976 39.547 184.753ZM43.5674 177.508C45.7836 177.542 48.0809 177.69 50.5084 178.022 57.652 176.484 74.9909 200.122 50.7164 213.271L72.3554 233.412C73.3957 238.658 68.6102 243.064 64.865 240.966 54.7391 232.573 47.9422 218.096 34.4872 215.788L35.3195 238.658C32.0598 241.525 28.592 241.875 24.9162 238.448L24.5 178.442C31.0021 178.337 36.919 177.406 43.5674 177.508Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M271.683 179.5C271.683 196.287 271.272 223.776 271.683 229.861 278.802 245.529 307.488 242.731 309.268 229.022 309.952 224.405 310.432 196.497 310.5 179.92L301.669 179.71 301.258 225.664C298.93 234.548 284.074 236.716 280.103 224.615L280.309 179.5 271.683 179.5Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M367.5 179.131 367.5 240.101C378.763 240.171 372.813 241.082 401.5 239.891L400.862 231.902C390.521 232.532 385.917 232.322 377.062 232.743L377.062 211.718 398.737 211.508 399.162 202.678C390.521 203.799 386.342 203.869 377.062 204.57L377.275 187.33C377.204 187.4 390.521 185.788 400.65 187.33 401.358 183.406 401.004 182.425 400.437 178.5L367.5 179.131Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  <path d="M353.393 179.313C345.628 178.686 333.035 177.85 329.467 179.313 313.166 188.16 321.631 210.382 334.714 211.496 349.615 211.496 353.183 232.186 337.232 232.186 330.586 231.907 326.878 231.21 321.701 230.723 320.162 234.624 321.561 236.017 321.491 238.664L339.541 239.5C367.106 235.529 356.262 206.481 340.8 205.018 325.619 202.649 326.598 188.16 337.442 186.627 341.64 185.652 347.936 186.558 353.393 186.836 353.393 184.328 354.653 182.448 353.393 179.313Z" stroke="#FFFFFF" stroke-width="0.333605" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd"/>
  `,
];
