<template>
  <CCard>
    <CCardBody>
      <h1 class="display-4">INTegrated Energy MAnagement - INTEMA</h1>

      <hr class="my-4" />
      <p class="lead">
        Welcome to the INTEMA.building tool!
        <br />
        A specialized tool for building energy simulations!
        <br />
        The app enables non-experts users with little to no modeling experience
        to examine the energetic impact of possible interventions.
        <br />
        <CRow>
          <CCol class="lebox">
            <router-link to="/building/system-selection">
              <CImage
                alt="INTEMA.building logo"
                :src="
                  require('../../public/building/intema.building_logo_text.svg')
                "
                fluid
              />
            </router-link>
          </CCol>
        </CRow>
      </p>
    </CCardBody>
  </CCard>

  <br />

  <CRow>
    <CCol>
      <CCard class="h-100">
        <CCardHeader>CPERI's energy modeling team</CCardHeader>
        <CCardBody>
          CERTH/CPERI has a team of experts in energy systems modelling,
          analysis and optimization. Simulations of various energy systems have
          been performed (district heating networks, electrical grids,
          combustion power systems etc.) using a variety of tools for static and
          dynamic modelling. With a deep understanding of energy modeling
          principles, our team can accurately predict energy performance and
          identify areas for optimization. The expertise in energy modeling
          allows us to provide tailored solutions (specialized software
          applications) for a wide range of energy-related challenges faced by
          businesses and organizations.
          <br />
          <CImage
            alt="CPERI logo"
            :src="require('../../public/cperi_logo.png')"
            fluid
            class="my-4"
          />
        </CCardBody>
        <CCardFooter>
          <BaseButton to="/about-us" text="Learn about us" />
        </CCardFooter>
      </CCard>
    </CCol>
    <CCol>
      <CCard class="h-100">
        <CCardHeader>Meet RINNO project</CCardHeader>
        <CCardBody>
          The EU-funded RINNO project aims to facilitate deep renovation of the
          building stock by addressing major technical and socio-economic
          barriers: it will offer a portfolio of innovative technologies,
          improved processes supported by an on-line platform and software
          tools, as well as new business models. RINNO will also develop,
          validate and demonstrate an operational interface with augmented
          intelligence and an occupant-centered approach that will streamline
          and facilitate the whole lifecycle of building renovation
          (planning-design, retrofitting, monitoring).
          <br />
          <CImage
            alt="RINNO logo"
            :src="require('../../public/rinno_logo.jpg')"
            fluid
            class="my-4"
          />
        </CCardBody>
        <CCardFooter>
          <BaseButton to="/about-rinno" text="More info" />
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>

  <br />

  <CRow> </CRow>
</template>

<script setup>
import { onMounted } from "vue";

import { useMainStore } from "@/stores/mainStore.js";
import { useBuildingStore } from "@/stores/buildingStore.js";

const mainStore = useMainStore();
const buildingStore = useBuildingStore();

mainStore.getUserData();
buildingStore.getMaterials();

onMounted(async () => {
  await buildingStore.retrieveItems("systems");
  console.log(buildingStore.systems);
});
</script>

<style>
.lebox:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  /* transform: scale(1.1); */
}
</style>
