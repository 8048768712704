<template>
  <div class="v-stepper-root mb-2">
    <TheStepperItem
      v-for="(step, index) in buildingRoutes"
      :key="index + 1"
      :to="step.url"
      :index="index + 1"
      :label="step.label"
    />
  </div>
</template>

<script setup>
import { reactive } from "vue";
import TheStepperItem from "@/components/layout/TheStepperItem.vue";

const buildingRoutes = reactive([
  { label: "IFC Import", url: "/building/ifc-import" },
  { label: "Location/ Weather", url: "/building/location-and-weather" },
  { label: "Thermal Zones", url: "/building/zones" },
  { label: "Building Elements", url: "/building/building-elements" },
  { label: "HVAC Systems", url: "/building/hvac-systems" },
  { label: "Electrical Systems", url: "/building/electrical-systems" },
  { label: "RINNO Tech", url: "/building/rinno-technologies" },
  { label: "Simulation & Results", url: "/building/simulation-and-results" },
]);
</script>

<style lang="scss" scoped>
.v-stepper-root {
  display: flex;
  width: inherit;
  user-select: none;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}
</style>
