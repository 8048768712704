<template>
  <CCard class="mb-3">
    <CCardHeader>Available systems</CCardHeader>
    <CCardBody class="fix-overflow-card-body">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell
              v-for="field in fields"
              :key="field.key"
              scope="col"
              >{{ field.label || pretifyName(field.key) }}</CTableHeaderCell
            >
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(row, indexX) in store.systems" :key="indexX">
            <CTableDataCell>{{ row.id }}</CTableDataCell>
            <CTableDataCell>{{ formatedDate(row.created_at) }}</CTableDataCell>
            <CTableDataCell>{{ row.PilotName }}</CTableDataCell>
            <CTableDataCell>{{ row.Scenario }}</CTableDataCell>
            <!-- <CTableDataCell>{{ row.notes }}</CTableDataCell> -->
            <CTableDataCell>
              <BaseButton
                size="sm"
                icon="cil-task"
                @click="() => loadSystem(row)"
                >{{ row.runs }} runs
              </BaseButton></CTableDataCell
            >
            <CTableDataCell>
              <BaseButton
                size="sm"
                color="danger"
                icon="cil-trash"
                @click="
                  () => {
                    deleteModal = true;
                    itemToBeDeleted = row;
                  }
                "
            /></CTableDataCell>
            <CTableDataCell>
              <BaseButton
                size="sm"
                color="warning"
                icon="edit-icon"
                @click="
                  () => {
                    editModal = true;
                    itemToBeEdited = row;
                    Object.assign(editForm, row);
                  }
                "
            /></CTableDataCell>
            <CTableDataCell>
              <BaseButton
                size="sm"
                color="warning"
                icon="cil-share-boxed"
                @click="
                  () => {
                    shareModal = true;
                    itemToBeShared = row;
                  }
                "
            /></CTableDataCell>
          </CTableRow>
          <CTableRow v-if="!store.systems.length">
            <CTableDataCell :colspan="fields.length">
              <div class="text-center my-5">
                <h2>
                  No items
                  <CIcon width="30" content="cil-ban" class="text-danger" />
                </h2>
              </div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CCardBody>
    <CCardFooter>
      <form @submit.prevent="addItem()">
        <BaseFormElement
          v-model="postForm.PilotName"
          label="Pilot name"
          type="text"
          placeholder="Please add a name for the system"
          disabled="true"
        />
        <BaseFormElement
          v-model="postForm.Scenario"
          label="Scenario"
          type="text"
          placeholder="Please add a scenario id"
        />
        <BaseFormElement
          v-model="postForm.notes"
          label="Description"
          type="textarea"
          placeholder="Please add a short description for the system under consideration"
        />
        <BaseButton class="float-end" text="Add system" type="submit" />
      </form>
      <BaseButton
        class="float-end"
        color="warning"
        text="Duplicate System"
        type="submit"
        @click="duplicateModal = true"
      />
      <BaseButton
        class="float-end"
        color="success"
        text="RINNO Results"
        @click="router.push('/building/rinno-results')"
      />
    </CCardFooter>

    <BaseModal
      v-model="editModal"
      color="warning"
      title="System edit"
      @submit="editItem()"
    >
      <BaseFormElement
        v-model="editForm.PilotName"
        label="Pilot name"
        type="text"
        placeholder="Please add a name for the system"
        disabled="true"
      />
      <BaseFormElement
        v-model="editForm.Scenario"
        label="Scenario"
        type="text"
        placeholder="Please add a scenario id"
      />
      <BaseFormElement
        v-model="editForm.notes"
        label="Description"
        type="textarea"
        placeholder="Please add a short description for the system under consideration"
      />
    </BaseModal>

    <BaseModal
      v-model="deleteModal"
      color="danger"
      title="System deletion"
      @submit="delItem()"
      >Are you sure you want to delete this system?
    </BaseModal>

    <BaseModal
      v-model="shareModal"
      color="warning"
      title="System share"
      @submit="shareSystem"
    >
      System {{ itemToBeShared.id }} is shared among users:
      {{ itemToBeShared.usernames }}
      <BaseFormElement
        v-model="shareForm.username"
        label="Username to be added"
        type="text"
      />
    </BaseModal>

    <BaseModal
      v-model="duplicateModal"
      color="warning"
      title="System duplication"
      @submit="duplicateSystem"
    >
      <label class="form-label">Select system</label>
      <BaseFormElement
        v-model="duplicateForm.id"
        type="select"
        :options="systemsOptionsSelect"
      />
      <label class="form-label">Pilot name</label>
      <BaseFormElement
        v-model="duplicateForm.PilotName"
        type="text"
        disabled="true"
      />
      <label class="form-label">Scenario</label>
      <BaseFormElement v-model="duplicateForm.Scenario" type="text" />
      <label class="form-label">Description</label>
      <BaseFormElement
        v-model="duplicateForm.notes"
        type="textarea"
        placeholder="Please add a short description for the new system."
        rows="4"
      />
    </BaseModal>
  </CCard>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { axios } from "@/common/api.service.js";
import { useMainStore } from "@/stores/mainStore.js";
import { useBuildingStore } from "@/stores/buildingStore.js";
import { formatedDate, pretifyName } from "@/common/utils.js";

const mainStore = useMainStore();
const store = useBuildingStore();
const router = useRouter();

const fields = [
  { key: "id", label: "ID" },
  { key: "created_at" },
  { key: "PilotName", label: "Pilot Name" },
  { key: "Scenario" },
  // { key: "notes" },
  { key: "selectBtn", label: "Select" },
  { key: "deleteBtn", label: "Delete" },
  { key: "editBtn", label: "Edit" },
  { key: "shareBtn", label: "Share" },
];

const postForm = reactive({
  PilotName: store.PilotName,
  Scenario: null,
  notes: "",
});

const defaultEdit = {
  PilotName: store.PilotName,
  Scenario: null,
  notes: "",
};
const editForm = reactive(defaultEdit);
const editModal = ref(false);
const itemToBeEdited = ref(null);

const deleteModal = ref(false);
const itemToBeDeleted = ref(null);

const shareModal = ref(false);
const itemToBeShared = ref(null);
const shareForm = reactive({
  username: null,
});

const duplicateModal = ref(false);
const duplicateForm = reactive({
  id: null,
  PilotName: store.PilotName,
  Scenario: null,
  notes: "",
});

const systemsOptionsSelect = computed(() => {
  var lol = [];
  store.systems.forEach((item) => {
    lol.push({ value: item.id, label: item.id });
  });
  return lol;
});

const loadSystem = async (item) => {
  await store.loadSystem(item.id);
  router.push("/building/ifc-import");
};

const addItem = () => {
  store.createItem("systems", postForm);
};

const editItem = async () => {
  await store.updateItem("systems", itemToBeEdited.value.id, editForm);
  Object.assign(editForm, defaultEdit);
  await store.retrieveItems("systems");
};

const delItem = () => {
  store.deleteItem("systems", itemToBeDeleted.value.id);
};

const duplicateSystem = () => {
  mainStore.showSpinner();
  return axios
    .post("api/building/duplicatesystem/", duplicateForm)
    .then((response) => {
      store.systems.push(response.data);
      duplicateForm.id = null;
      duplicateForm.PilotName = store.PilotName;
      duplicateForm.Scenario = null;
      duplicateForm.notes = "";
    })
    .catch((error) => mainStore.addFailToast(error))
    .finally(() => mainStore.hideSpinner());
};

const shareSystem = () => {
  store
    .updateItem("systems", itemToBeShared.value.id, shareForm)
    .then((shareForm.username = null));
};
</script>
