<template>
  <CCard>
    <CCardBody>
      <h1 class="display-4">RINNO</h1>
      <p class="lead">
        An augmented intelligence-enabled stimulating framework for deep energy
        renovation delivering occupant-centred innovations
      </p>
      <hr class="my-4" />
      <img
        src="../../../public/rinno_logo.jpg"
        alt="RINNO logo"
        class="float-end"
        style="width: 400px"
      />
      <p>
        RINNO is a
        <a href="https://ec.europa.eu/programmes/horizon2020/" target="_blank"
          >Horizon 2020</a
        >
        project that aims to deliver a set of processes that when working
        together give a system, repository, marketplace, and enabling workflow
        process for managing deep renovation projects.
      </p>
      <p>
        The ultimate objective of RINNO is to dramatically accelerate the rate
        of deep renovation in the EU by reducing the time, effort and cost of
        deep renovation while improving energy performance and stakeholder
        satisfaction.
      </p>
      <p>
        This concept has at its foundation, a set of cost-attractive,
        environmentally friendly, multi-functional and easily applicable
        building-related innovations, grouped into:
      </p>
      <ul>
        <li>plug-n-play, modular building envelope solutions;</li>
        <li>RES, hybrid and storage solutions.</li>
      </ul>
      <p>
        RINNO couples the above with innovative retrofitting processes, methods
        and tools, characterised by low tenants’ disruption. These comprise:
        efficient (off/on-site) construction strategies, on-the-job AR
        facilitating environment and multi-stakeholder collaboration, which are
        expected to shrink the time and cost required for deep renovation, while
        improving buildings’ performance; all with a short payback period of
        less than 4 years on average.
      </p>
      <p>
        The above will be supported business-wise with novel business models
        aligned with the circular economy principles, enriched with investment
        de-risking tools and advanced crowd-equity/crowd-lending schemes. RINNO
        is expected to impact the EU inefficient building stock by
      </p>
      <ul>
        <li>Contributing to an ambitious annual renovation rate of 3.5%</li>
        <li>Primary energy savings of 165 GWh/year</li>
        <li>A reduction of electricity cost by at least 30%</li>
        <li>
          A total cost / time reduction in comparison with typical renovation by
          more than 30% and 40% respectively
        </li>
        <li>An estimated reduction of 40,400 tons CO2-eq/year.</li>
      </ul>
      <p>
        RINNO optimised renovation roadmap will be demonstrated at 4 large scale
        (3,386 m²) pilot use cases in <b>Poland</b>, <b>Greece</b>,
        <b>Denmark</b> and <b>France</b> after being pre-piloted, covering
        different EU climatic zones and markets of diverse maturity in the
        renovation sector.
      </p>
      <p>
        For more information please visit
        <a href="https://rinno-h2020.eu/" target="_blank">RINNO's website</a>.
      </p>
    </CCardBody>
  </CCard>
</template>

<script setup></script>

<style scoped>
.imageCss {
  width: 200px;
}
</style>
