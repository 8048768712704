<template>
  <CCard class="mb-3">
    <CCardBody>
      <h1 class="display-4">Materials Library</h1>
      <hr class="my-4" />

      <p>
        A repository that includes parameters for typical construction
        materials. The library can be extended by user-defined materials.
      </p>
    </CCardBody>
  </CCard>

  <DataCard
    item-name="material"
    title="Materials"
    :store="store"
    :fields="fields"
    :items="store.materials"
    :form-fields="formFields"
  />
</template>

<script setup>
import { useBuildingStore } from "@/stores/buildingStore.js";

const store = useBuildingStore();

const fields = [
  { key: "id", label: "id" },
  { key: "name" },
  { key: "conductivity", label: "Thermal conductivity [W/(m·K)]" },
  { key: "capacity", label: "Specific heat capacity [J/(kg·K)]" },
  { key: "density", label: "Density [kg/m³]" },
  { key: "editBtn", label: "Edit" },
  { key: "deleteBtn", label: "Delete" },
];

const formFields = {
  name: { type: "text", label: "Name", initial: null },
  conductivity: {
    type: "number",
    label: "Thermal conductivity [W/(m·K)]",
    initial: null,
  },
  capacity: {
    type: "number",
    label: "Specific heat capacity [J/(kg·K)]",
    initial: null,
  },
  density: {
    type: "number",
    label: "Density [kg/m³]",
    initial: null,
  },
};
</script>
