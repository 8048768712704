<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem v-for="(item, index) in breadcrumbs" :key="index">
      <router-link
        class="bread-link"
        :link-exact-active-class="item.active"
        :to="item.active ? '' : item.path"
        >{{ item.name }}</router-link
      >
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const breadcrumbs = ref();

const getBreadcrumbs = () => {
  return router.currentRoute.value.matched
    .filter((x) => {
      return x.name != null;
    })
    .map((route) => {
      return {
        active: route.path === router.currentRoute.value.fullPath,
        name: route.name,
        path: `${router.options.history.base}${route.path}`,
      };
    });
};

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs();
});

onMounted(() => {
  breadcrumbs.value = getBreadcrumbs();
});
</script>

<style lang="scss">
.bread-link {
  text-decoration: none;
  color: inherit;
  opacity: 0.75;

  &:hover {
    opacity: 0.95;
    text-decoration: none;
  }
}
</style>
