<template>
  <CCard class="mb-3">
    <CCardBody>
      <h1 class="display-4">Feedback form</h1>
      Thank you for taking the time to provide us with your feedback. Your input
      is valuable to us and helps us improve our software. Please take a few
      minutes to complete this survey and let us know what you think. Your
      responses will be kept confidential and will only be used for research
      purposes.
      <hr />
      <CRow class="justify-content-center">
        <CCol xs="10">
          <CForm @submit.prevent="postFeedback">
            <BaseFormElement
              v-model="formData.tool"
              label="Which software did you use?"
              label-col="6"
              type="select"
              :options="toolsOptions"
            />
            <BaseFormElement
              v-model="formData.value"
              label="For your, how valuable is our software"
              label-col="6"
              type="number"
            />
            <BaseFormElement
              v-model="formData.easy"
              label="How easy-to-use is our software"
              label-col="6"
              type="number"
            />
            <BaseFormElement
              v-model="formData.lag"
              label="Have you experienced softwrare lagging or crashing?"
              label-col="6"
              type="select"
              :options="yesNo"
            />
            <BaseFormElement
              v-model="formData.recommend"
              label="Would you recommend this software?"
              label-col="6"
              type="select"
              :options="yesNo"
            />
            <BaseFormElement
              v-model="formData.notes"
              label="Let us know about your experience"
              label-col="6"
              type="textarea"
              rows="6"
            />
            <BaseButton class="float-end" text="Submit" type="submit" />
          </CForm>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script setup>
import { reactive } from "vue";
import { useMainStore } from "@/stores/mainStore.js";
import { axios } from "@/common/api.service.js";

const store = useMainStore();

const toolsOptions = [
  { value: "INTEMA.building", label: "INTEMA.building" },
  { value: "INTEMA.grid", label: "INTEMA.grid" },
  { value: "Energy forecasting", label: "Energy forecasting" },
  { value: "Battery scheduler", label: "Battery scheduler" },
  { value: "RES generation", label: "RES generation" },
];

const yesNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const formDataDefault = {
  tool: null,
  value: null,
  easy: null,
  lag: null,
  recommend: null,
  notes: null,
};

const formData = reactive({ ...formDataDefault });

const postFeedback = () => {
  store.showSpinner();
  return axios
    .post("api/building/feedback/", { form: formData.value })
    .then(() => {
      Object.assign(formData, formDataDefault);
      store.addToast({
        header: "Saved",
        color: "light",
        text: "Successfully submitted, thank you again for your time!",
      });
    })
    .catch((error) => store.addFailToast(error))
    .finally(() => store.hideSpinner());
};
</script>
