import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import CoreuiVue from "@coreui/vue";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.use(CoreuiVue);
app.provide("icons", icons);
app.component("CIcon", CIcon);

import vSelect from "vue-select";
app.component("VSelect", vSelect);
// to css to evala me cdn giati varage

// Globally declared custom components
import BaseButton from "@/components/base/BaseButton";
app.component("BaseButton", BaseButton);
import BaseFormElement from "@/components/base/BaseFormElement";
app.component("BaseFormElement", BaseFormElement);
import BaseModal from "@/components/base/BaseModal";
app.component("BaseModal", BaseModal);
import BaseSelect from "@/components/base/BaseSelect";
app.component("BaseSelect", BaseSelect);

import DataTable from "@/components/DataTable";
app.component("DataTable", DataTable);
import DataCard from "@/components/DataCard";
app.component("DataCard", DataCard);

app.mount("#app");
