import {
  cilAccountLogout,
  cilBan,
  cilMenu,
  cilBoltCircle,
  cilTrash,
  cilSquare,
  cilSend,
  cilShareBoxed,
  cilAddressBook,
  cilBatteryFull,
  cilExternalLink,
  cilBell,
  cilBook,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilCloudUpload,
  cilChartLine,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGraph,
  cilGrid,
  cilFile,
  cilFire,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMap,
  cilMagnifyingGlass,
  cilOptions,
  cilPencil,
  cilPuzzle,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTask,
  cilUser,
  cilXCircle,
  cilAsterisk,
  cilGradient,
  cilInfo,
  cibLinkedin,
  cibGithub,
} from "@coreui/icons";

import { rinnoLogo } from "./projects_logos/rinno_logo";
import { ianosLogo } from "./projects_logos/ianos_logo";
import { incubeLogo } from "./projects_logos/incube_logo";
import { rehouseLogo } from "./projects_logos/rehouse_logo";
import { sinnogenesLogo } from "./projects_logos/sinnogenes_logo";

import { editIcon } from "./editIcon";
import { energyIcon } from "./energyIcon";
import { sourceIcon } from "./sourceIcon";
import { stabilityIcon } from "./stabilityIcon";
import { costReductionIcon } from "./costReductionIcon";
import { networkIcon } from "./networkIcon";
import { resIcon } from "./resIcon";

export const iconsSet = Object.assign({
  cilAccountLogout,
  cilBan,
  sourceIcon,
  energyIcon,
  stabilityIcon,
  costReductionIcon,
  networkIcon,
  cilShareBoxed,
  resIcon,
  cilMenu,
  cilSend,
  cilExternalLink,

  rinnoLogo,
  ianosLogo,
  incubeLogo,
  rehouseLogo,
  sinnogenesLogo,

  cilBoltCircle,
  editIcon,
  cilTrash,
  cilSquare,
  cilAddressBook,
  cilBatteryFull,
  cilBell,
  cilBuilding,
  cilBook,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilCloudUpload,
  cilChartLine,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGraph,
  cilGrid,
  cilFile,
  cilFire,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMap,
  cilMagnifyingGlass,
  cilOptions,
  cilPencil,
  cilPuzzle,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilTask,
  cilUser,
  cilXCircle,
  cilAsterisk,
  cilGradient,

  cilInfo,

  cibLinkedin,
  cibGithub,
});
