<template>
  <CSidebar
    position="fixed"
    :unfoldable="mainStore.sidebarUnfoldable"
    :visible="mainStore.sidebarVisible"
    @visible-change="(event) => mainStore.updateSidebarVisible(event)"
  >
    <router-link to="/dashboard">
      <CSidebarBrand>
        <CIcon
          custom-class-name="sidebar-brand-full"
          :icon="buildingLogo"
          :height="45"
        />
        <CIcon
          custom-class-name="sidebar-brand-narrow"
          :icon="buildingSygnet"
          :height="45"
        />
      </CSidebarBrand>
    </router-link>

    <CSidebarNav>
      <CNavItem>
        <router-link to="/dashboard" class="nav-link">
          <CIcon class="nav-icon" icon="cil-speedometer" />
          Dashboard
        </router-link>
      </CNavItem>

      <CNavTitle>Ancillary Modules</CNavTitle>
      <CNavItem>
        <router-link to="/materials-library" class="nav-link">
          <CIcon class="nav-icon" icon="cil-gradient" />
          Materials Library
        </router-link>
      </CNavItem>

      <CNavTitle>Info</CNavTitle>
      <CNavItem>
        <router-link to="/documentation" class="nav-link">
          <CIcon class="nav-icon" icon="cil-book" />
          Documentation
        </router-link>
      </CNavItem>

      <CNavItem>
        <router-link to="/about-us" class="nav-link">
          <CIcon class="nav-icon" icon="cil-address-book" />
          About Us
        </router-link>
      </CNavItem>
      <CNavItem>
        <router-link to="/references" class="nav-link">
          <CIcon class="nav-icon" icon="cil-asterisk" />
          References
        </router-link>
      </CNavItem>

      <CNavItem>
        <router-link to="/about-rinno" class="nav-link">
          <CIcon class="nav-icon" icon="rinno-logo" />
          RINNO
        </router-link>
      </CNavItem>
    </CSidebarNav>

    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="mainStore.toggleUnfoldable"
    />
  </CSidebar>
</template>

<script setup>
import { useMainStore } from "@/stores/mainStore.js";

import { buildingLogo } from "@/assets/brand/building_logo";
import { buildingSygnet } from "@/assets/brand/building_sygnet";

const mainStore = useMainStore();
</script>

<style scoped>
.sidebar-nav::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.158);
  border-radius: 10px;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #636f83;
  border-radius: 10px;
}
</style>
