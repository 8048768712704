import { defineStore } from "pinia";
import { ref } from "vue";
import { axios } from "@/common/api.service";

export const useMainStore = defineStore("mainStore", () => {
  const main_token = ref(localStorage.getItem("token"));

  const user = ref(null);

  const spinner = ref(false);
  const toasts = ref([]);

  const sidebarVisible = ref(true);
  const sidebarUnfoldable = ref(false);

  function getUserData() {
    let endpoint = "api/user/";
    return axios
      .get(endpoint)
      .then((resp) => {
        user.value = resp.data;
      })
      .catch(() => {
        // no error toast giati petage 2 asxeta stin arxi
      })
      .finally(async () => {
        hideSpinner();
      });
  }

  function updateUserData(data) {
    let endpoint = "api/user/";
    return axios
      .patch(endpoint, data)
      .then((resp) => {
        user.value = resp.data;

        addToast({
          header: "Saved",
          color: "light",
          text: "User data have been successfully updated.",
        });
      })
      .catch((error) => addFailToast(error))
      .finally(async () => {
        hideSpinner();
      });
  }

  function showSpinner() {
    spinner.value = true;
  }

  function hideSpinner() {
    spinner.value = false;
  }

  /**
   * Adds a tost component.
   * @param {{header:string, color:string, text:string}} toast Toasts should have this specific shape
   */
  function addToast(toast) {
    toasts.value.push(toast);
  }

  /**
   * Adds a FAIL tost component -> is red and has Fail as title
   * @param {string} error The error object of message to be displayed
   */
  function addFailToast(error) {
    toasts.value.push({
      header: "Fail",
      color: "danger",
      text: error.response.data || error,
    });
  }

  function toggleSidebar() {
    sidebarVisible.value = !sidebarVisible.value;
  }
  function toggleUnfoldable() {
    sidebarUnfoldable.value = !sidebarUnfoldable.value;
  }
  function updateSidebarVisible(payload) {
    sidebarVisible.value = payload;
  }

  return {
    main_token,
    user,
    spinner,
    toasts,
    sidebarVisible,
    sidebarUnfoldable,
    getUserData,
    updateUserData,
    showSpinner,
    hideSpinner,
    addToast,
    addFailToast,
    toggleSidebar,
    toggleUnfoldable,
    updateSidebarVisible,
  };
});
