<template>
  <CCard class="mb-3">
    <CCardHeader>{{ title }}</CCardHeader>
    <CCardBody class="fix-overflow-card-body">
      <CRow>
        <CCol v-if="image" class="col-4">
          <CImage :src="image" class="img-fluid" />
        </CCol>
        <CCol>
          <DataTable
            :fields="fields"
            :items="items"
            @edit-btn-clicked="
              (item) => {
                editModal = true;
                itemToBeEdited = item;
                Object.assign(editForm, item);
              }
            "
            @delete-btn-clicked="
              (item) => {
                deleteModal = true;
                itemToBeDeleted = item;
              }
            "
            @download-btn-clicked="(item) => downloadItem(item)"
            @upload-btn-clicked="(item) => emit('uploadBtnClicked', item)"
            @select-btn-clicked="
              (item, label) => emit('selectBtnClicked', item, label)
            "
            @share-btn-clicked="
              (item) => {
                shareModal = true;
                itemToBeShared = item;
              }
            "
          />
        </CCol>
      </CRow>
    </CCardBody>
    <CCardFooter v-if="props.postForm">
      <form
        @submit.prevent="
          parentHandler ? emit('submitPostForm', postForm) : addItem()
        "
      >
        <BaseFormElement
          v-for="(field1, index) in Object.keys(formFields)"
          :key="'post' + index"
          v-model="postForm[field1]"
          :label="formFields[field1].label"
          :type="formFields[field1].type"
          v-bind="formFields[field1].rest"
        />
        <BaseButton
          class="float-end"
          :text="'Add ' + upper(itemName)"
          type="submit"
        />
        <slot name="buttons"></slot>
      </form>
    </CCardFooter>

    <BaseModal
      v-if="props.editForm"
      v-model="editModal"
      color="warning"
      :title="upper(itemName) + ' edit'"
      @submit="
        props.parentHandler
          ? emit('submitEditForm', itemToBeEdited, editForm)
          : editItem()
      "
    >
      <BaseFormElement
        v-for="(field2, index) in Object.keys(formFields)"
        :key="'edit' + index"
        v-model="editForm[field2]"
        :label="formFields[field2].label"
        :type="formFields[field2].type"
        v-bind="formFields[field2].rest"
      />
    </BaseModal>

    <BaseModal
      v-model="deleteModal"
      color="danger"
      :title="upper(itemName) + ' deletion'"
      @submit="
        props.parentHandler ? emit('submitDelete', itemToBeDeleted) : delItem()
      "
      >Are you sure you want to delete this {{ itemName }}?
    </BaseModal>

    <BaseModal
      v-model="shareModal"
      color="warning"
      title="System share"
      @submit="shareSystem"
    >
      System {{ itemToBeShared.ext_id }} is shared among users:
      {{ itemToBeShared.usernames }}
      <BaseFormElement
        v-model="shareForm.username"
        label="Username to be added"
        type="text"
      />
    </BaseModal>
  </CCard>
</template>

<script setup>
/**
 * A Card component which includes a DataTable in the main body.
 * Optionally it contains a post-form in the footer and/or an edit-form in modal.
 * There is also a parentHandler prop, when true, all main submit events (add,edit,delete)
 * are propagated upwards to be implemented in a custom way by the parent.
 * The component supports edit, delete, download and select.
 * The getPlural() function should be implemented in store
 * Select and upload are always emitted as events to be handled by the parent.
 */
import { ref, reactive, computed } from "vue";
import { axios } from "@/common/api.service.js";

const props = defineProps({
  title: {
    type: String,
    default: "Some data",
  },
  /**
   * itemName should be in singular form, i.e. wall, line. etc.
   * the component brings the singlural form from the store
   */
  itemName: {
    type: String,
    required: true,
  },
  store: {
    type: Object,
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
  items: {
    type: [Array, null],
    required: true,
  },
  formFields: {
    type: Object,
    default: undefined,
  },
  postForm: {
    type: Boolean,
    default: true,
  },
  editForm: {
    type: Boolean,
    default: true,
  },
  parentHandler: {
    type: Boolean,
    default: false,
  },
  image: {
    type: undefined,
    default: null,
  },
});

var _postForm = {};
var _editForm = {};
for (var field in props.formFields) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  _postForm[field] = props.formFields[field].initial;
  // eslint-disable-next-line vue/no-setup-props-destructure
  _editForm[field] = props.formFields[field].initial;
}
// eslint-disable-next-line vue/no-dupe-keys
let postForm = reactive({ ..._postForm });
// eslint-disable-next-line vue/no-dupe-keys
let editForm = reactive({ ..._editForm });

const editModal = ref(false);
const itemToBeEdited = ref(null);

const deleteModal = ref(false);
const itemToBeDeleted = ref(null);

const shareModal = ref(false);
const itemToBeShared = ref(null);
const shareForm = reactive({
  username: null,
});

const plural = computed(() => props.store.getPlural(props.itemName));

const upper = (name) => name.charAt(0).toUpperCase() + name.slice(1);

const addItem = () => {
  props.store
    .createItem(plural.value, postForm)
    .then(Object.assign(postForm, _postForm));
};

const editItem = () => {
  props.store.updateItem(plural.value, itemToBeEdited.value.id, editForm);
};

const delItem = () => {
  props.store.deleteItem(plural.value, itemToBeDeleted.value.id);
};

const downloadItem = (item) => {
  let endpoint = props.store.baseUrl + plural.value + "/" + item.id + "/";
  return axios.get(endpoint).then((resp) => {
    let text = resp.data.res_json; // edw to thelei ws json string. ara to afinw etsi
    let filename = "intema_res.json";
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/json;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  });
};

const shareSystem = () => {
  props.store
    .updateItem(plural.value, itemToBeShared.value.id, shareForm)
    .then((shareForm.username = null));
};

// propagate the rest of DataTable functionality upwards as events
const emit = defineEmits([
  "selectBtnClicked",
  "uploadBtnClicked",
  "submitPostForm",
  "submitEditForm",
  "submitDelete",
]);
</script>
