import { createRouter, createWebHistory } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout.vue";

import Dashboard from "@/views/DashboardView.vue";
import BuildingSystemSelection from "@/views/building/BuildingSystemSelection.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
    component: DefaultLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "materials-library",
        name: "MaterialsLibrary",
        component: require("@/views/MaterialsLibraryView.vue").default,
      },
      {
        path: "documentation",
        name: "Documentation",
        component: () => import("@/views/info/DocumentationView.vue"),
      },
      {
        path: "about-rinno",
        name: "About RINNO",
        component: require("@/views/info/AboutRINNOView.vue").default,
      },
      {
        path: "about-us",
        name: "AboutUs",
        component: require("@/views/info/AboutUsView.vue").default,
      },
      {
        path: "references",
        name: "References",
        component: require("@/views/info/ReferencesView.vue").default,
      },
      {
        path: "profile",
        name: "Profile",
        component: require("@/views/user/ProfileView.vue").default,
      },
      {
        path: "feedback",
        name: "Feedback",
        component: require("@/views/user/FeedbackView.vue").default,
      },
    ],
  },
  {
    path: "/building",
    redirect: "/dashboard",
    name: "INTEMA.building",
    component: DefaultLayout,
    children: [
      {
        path: "system-selection",
        name: "Building System Selection",
        component: BuildingSystemSelection,
      },
      {
        path: "ifc-import",
        name: "IFC Import",
        component: () =>
          import("@/views/building/data-definition/IFCImportView.vue"),
      },
      {
        path: "location-and-weather",
        name: "Location & Weather",
        component: () =>
          import("@/views/building/data-definition/LocationAndWeatherView.vue"),
      },
      {
        path: "zones",
        name: "Zones",
        component: () =>
          import("@/views/building/data-definition/ZonesView.vue"),
      },
      {
        path: "building-elements",
        name: "Building Elements",
        component: () =>
          import("@/views/building/data-definition/BuildingElementsView.vue"),
      },
      {
        path: "hvac-systems",
        name: "HVAC Systems",
        component: () =>
          import("@/views/building/data-definition/HVACSystemsView.vue"),
      },
      {
        path: "electrical-systems",
        name: "Electrical Systems",
        component: () =>
          import("@/views/building/data-definition/ElectricalSystemsView.vue"),
      },
      {
        path: "rinno-technologies",
        name: "RinnoTechnologies",
        component: () =>
          import("@/views/building/data-definition/RinnoTechnologiesView.vue"),
      },
      {
        path: "rinno-results",
        name: "RINNO Results",
        component: () => import("@/views/building/RinnoResults.vue"),
      },
      {
        path: "simulation-and-results",
        name: "Simulation and Results",
        component: () =>
          import(
            "@/views/building/data-definition/SimulationAndResultsView.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: require("@/views/LoginView.vue").default,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: require("@/views/RegisterView.vue").default,
  // },
  // {
  //   path: "/:catchAll(.*)",
  //   component: require("@/views/Page404View.vue").default,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
