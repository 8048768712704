export const sourceIcon = [
  "512 512",
  `
<g>
<path d="M166.5 273.071C239.606 144.705 270.185 366.261 346.5 242.357" stroke="var(--ci-primary-color, currentColor)" stroke-width="25.3251" stroke-linecap="round" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
<path d="M0 0 0.000104953 52.8387" stroke="var(--ci-primary-color, currentColor)" stroke-width="25.3251" stroke-miterlimit="8" fill="none" fill-rule="evenodd" transform="matrix(1 0 0 -1 256.5 67.3386)"/>
<path d="M0 0 0.000104953 58.1144" stroke="var(--ci-primary-color, currentColor)" stroke-width="25.3251" stroke-miterlimit="8" fill="none" fill-rule="evenodd" transform="matrix(1 0 0 -1 256.5 489.614)"/>
<path d="M69.5 256.5C69.5 153.223 153.223 69.5 256.5 69.5 359.777 69.5 443.5 153.223 443.5 256.5 443.5 359.777 359.777 443.5 256.5 443.5 153.223 443.5 69.5 359.777 69.5 256.5Z" stroke="var(--ci-primary-color, currentColor)" stroke-width="25.3251" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
</g>`,
];
