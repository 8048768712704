<template>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="8">
          <CCardGroup>
            <CCard class="py-5" style="width: 44%; background-color: white">
              <CCardBody class="text-center m-4">
                <CImage
                  alt="INTEMA.building logo"
                  :src="
                    require('../../public/building/intema.building_logo_text_apo_katw.svg')
                  "
                  fluid
                />
              </CCardBody>
            </CCard>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="login">
                  <h1>Login</h1>
                  <p class="text-medium-emphasis">Sign In to your account</p>
                  <CInputGroup class="mb-3">
                    <CInputGroupText>
                      <CIcon icon="cil-user" />
                    </CInputGroupText>
                    <CFormInput
                      v-model="formData.username"
                      placeholder="Username"
                      autocomplete="username"
                    />
                  </CInputGroup>
                  <CInputGroup class="mb-4">
                    <CInputGroupText>
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      v-model="formData.password"
                      type="password"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol :xs="6">
                      <BaseButton class="px-4" text="Login" type="submit" />
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { axios } from "@/common/api.service";

const router = useRouter();

const formData = reactive({
  username: null,
  password: null,
});

const login = async () => {
  await axios
    .post("api/token/force/", { username: formData.username })
    .then(() => {
      router.push("/");
    })
    .catch((error) => {
      console.log(error);
      router.push("/login");
    });
};
</script>
