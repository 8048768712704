export const energyIcon = [
  "512 512",
  `
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="var(--ci-primary-color, currentColor)" class="ci-primary">
<path d="M1495 5095 c-14 -13 -25 -35 -25 -48 0 -45 30 -66 133 -97 54 -15
101 -30 103 -33 2 -2 -29 -16 -68 -32 -123 -47 -339 -161 -464 -244 -543 -361
-934 -939 -1063 -1571 -38 -188 -46 -281 -46 -537 0 -240 1 -253 21 -280 26
-34 86 -44 114 -18 18 16 19 32 13 239 -6 235 2 350 38 531 124 620 507 1186
1039 1535 124 81 348 191 479 235 58 19 106 34 107 32 2 -2 -17 -75 -41 -162
-25 -88 -45 -170 -45 -182 0 -28 45 -63 80 -63 15 0 35 9 45 20 10 11 48 127
86 260 70 245 73 268 38 297 -15 12 -475 143 -503 143 -9 0 -28 -11 -41 -25z"/>
<path d="M2348 5053 c-67 -7 -99 -75 -59 -124 l19 -24 209 3 c212 3 359 -9
518 -44 618 -137 1165 -512 1505 -1034 97 -150 265 -509 246 -528 -3 -2 -80
14 -170 37 -175 43 -203 44 -225 2 -17 -31 -13 -58 12 -85 18 -21 62 -35 263
-86 258 -65 283 -67 304 -18 18 44 120 455 120 486 0 40 -31 72 -68 72 -51 0
-68 -22 -92 -122 -28 -113 -36 -131 -46 -103 -28 81 -110 247 -174 355 -402
669 -1110 1124 -1885 1210 -85 9 -397 11 -477 3z"/>
<path d="M2440 4344 c-359 -34 -638 -135 -922 -334 -114 -79 -322 -286 -401
-397 -231 -325 -339 -660 -340 -1048 -2 -391 108 -730 340 -1059 78 -110 287
-316 401 -396 646 -453 1451 -450 2096 7 110 78 316 287 396 401 150 214 249
438 299 678 119 577 -53 1181 -454 1592 -90 92 -122 103 -163 59 -39 -41 -29
-71 48 -155 243 -259 390 -562 440 -907 15 -102 15 -336 0 -444 -95 -685 -612
-1241 -1285 -1381 -731 -153 -1480 214 -1805 885 -358 739 -118 1618 568 2078
504 339 1201 361 1714 57 80 -48 118 -51 147 -14 51 64 17 104 -161 192 -288
142 -636 212 -918 186z"/>
<path d="M2730 3883 c-14 -2 -37 -9 -53 -15 -33 -13 -1041 -1130 -1074 -1190
-47 -86 -12 -204 75 -256 l47 -27 260 -3 c261 -3 285 -7 285 -41 0 -9 -70
-209 -155 -444 -172 -476 -176 -494 -130 -573 56 -95 180 -126 270 -68 54 35
1236 1416 1266 1479 45 96 5 206 -93 256 -34 17 -61 19 -292 19 -141 0 -266 4
-280 10 -18 7 -26 17 -26 34 0 14 29 151 65 306 67 292 75 352 51 399 -17 33
-79 89 -110 100 -32 12 -78 18 -106 14z m73 -174 c7 -12 -9 -97 -57 -310 -78
-340 -81 -382 -29 -446 61 -77 65 -77 376 -83 288 -5 296 -7 297 -47 0 -13
-40 -61 -732 -873 -331 -388 -480 -556 -496 -558 -28 -4 -52 14 -52 38 0 9 70
210 156 446 144 398 155 433 152 486 -5 71 -43 129 -102 158 -39 18 -62 20
-287 20 -258 0 -291 5 -297 46 -2 15 130 169 503 583 446 495 509 562 532 559
14 -2 30 -10 36 -19z"/>
<path d="M4929 2891 l-24 -19 -1 -289 c0 -306 -6 -365 -59 -573 -172 -681
-635 -1260 -1250 -1561 -123 -60 -342 -145 -351 -136 -2 2 17 75 41 163 25 87
45 166 45 175 0 9 -9 28 -21 43 -26 33 -77 36 -104 6 -10 -11 -48 -126 -86
-260 -69 -245 -72 -269 -38 -297 8 -6 124 -42 257 -79 228 -64 246 -67 270
-53 37 20 51 63 31 97 -11 22 -32 33 -104 55 -140 44 -138 39 -39 76 498 188
972 611 1251 1116 108 195 189 399 244 616 54 216 64 299 64 589 0 286 -5 318
-51 339 -35 15 -48 14 -75 -8z"/>
<path d="M182 2000 c-12 -5 -26 -20 -32 -32 -18 -44 -120 -455 -120 -486 0
-40 31 -72 68 -72 51 0 68 22 92 122 30 122 36 132 51 88 23 -65 146 -304 202
-393 351 -554 911 -953 1547 -1101 169 -40 303 -57 495 -63 215 -7 309 1 336
28 24 24 24 70 1 99 -18 22 -23 22 -273 25 -209 1 -274 5 -364 22 -356 68
-639 181 -923 370 -356 236 -633 550 -812 918 -68 140 -125 283 -116 293 3 2
80 -14 171 -37 153 -38 168 -40 194 -26 47 24 55 69 19 109 -19 21 -63 35
-262 85 -132 34 -242 61 -245 60 -3 0 -16 -4 -29 -9z"/>
</g>
`,
];
