<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CIcon class="mx-2" icon="cil-user" size="lg" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <router-link to="/profile" class="nav-link">
        <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>
      </router-link>
      <router-link to="/feedback" class="nav-link">
        <CDropdownItem> <CIcon icon="cil-speech" /> Feedback </CDropdownItem>
      </router-link>
    </CDropdownMenu>
  </CDropdown>
</template>

<script setup></script>
