<template>
  <CCard>
    <CCardBody>
      <h1 class="display-4">CERTH/CPERI energy systems simulation team</h1>
      <p class="lead">A few words about us.</p>
      <hr class="my-4" />
      <p>
        The Centre for Research and Technology Hellas
        <a href="https://www.certh.gr" target="_blank">CERTH</a> is one of the
        largest research centres in Greece; top 1 in north Greece. It was
        founded in 2000 and is located in Thessaloniki. Its mission is to
        promote the triplet Research – Development – Innovation by conducting
        high quality research and developing innovative products and services
        while building strong partnerships with industry and strategic
        collaborations with academia and other research and technology
        organisations in Greece and abroad. CERTH consists of five (5)
        Institutes and the Central Directorate and is governed by its Board of
        Directors. The institutes are:
      </p>
      <ul>
        <li>
          Information Technologies Institute
          <a href="https://www.iti.gr" target="_blank">ITI</a>
        </li>
        <li>
          Chemical Process &amp; Energy Resources Institute
          <a href="https://www.cperi.certh.gr" target="_blank">CPERI</a>
        </li>
        <li>
          Hellenic Institute of Transport
          <a href="https://www.imet.gr" target="_blank">HIT</a>
        </li>
        <li>
          Institute of Applied Biosciences
          <a href="https://www.inab.certh.gr" target="_blank">INAB</a>
        </li>
        <li>
          Institute of Bio-Economy and Agri-Technology
          <a href="https://ibo.certh.gr/" target="_blank">IBO</a>
        </li>
      </ul>
      <p>
        CERTH is essentially a self-supported Research Centre generating an
        average annual turnover of ~ € 25 Million coming from:
      </p>
      <ol>
        <li>more than 30% from bilateral industrial research contracts</li>
        <li>about 60% from competitive research projects</li>
        <li>less than 10% as government institutional funding.</li>
      </ol>
      <p>
        More than <em>800</em> people work at CERTH with the majority being
        scientists. CERTH has received numerous awards and distinctions such as
        the
        <em
          >European Descartes Prize, the European Research Council (ERC)
          Advanced Grant, Microsoft International Contest Prize, the Trading
          Agents Competition Award</em
        >
        and <em>many more</em> and is listed among the
        <em>Top-25 of the EU’s Research Centres</em> with the highest
        participation in H2020 competitive research grants . CERTH has
        participated successfully in more than <em>1.200</em> competitive
        research projects (with a total budget exceeding 450 M€ and involving
        more than 1.100 international partner organizations) financed by the
        European Union (EU), leading industries from USA, Japan and Europe and
        the Greek Government via the General Secretariat of Research and
        Technology (GSRT). CERTH’s research results (more than 350
        publications/year) have significant scientific impact (about 7.100
        heterocitations/year) .
      </p>
      <p>
        CPERI is classified among the Institutes of Excellence in Greece and
        employs a scientific staff of about 250 people, while its inflows is
        around to EUR 10 million €/year. In this light, the total budget of
        bioenergy and energy recovery projects by the utilisation of clean fuels
        is until now over 14 million €. Moreover, it holds the 16th position
        among the European Research Institutes (top 50 REC organisations) having
        signed grant agreements in FP7 in terms of counts of participations for
        the period 2007-2013. On March 30, 2012 Chemical Process Engineering
        Research Institute (CPERI) merged with Institute for Solid Fuels
        Technology and Applications (ISFTA) to establish the Chemical Process
        &amp; Energy Resources Institute (CPERI). The new established Research
        Institute is a member of the Centre for Research and Technology-Hellas
        (CERTH). CPERI contributes to the increased competitiveness of the Greek
        and European industry by providing unique and innovative solutions to
        research problems of technological and/or commercial interest.
      </p>
      <p>
        Our team consists of scientists with research duties such as modeling,
        development and optimization of energy system models. Their main
        interests are modeling and software development.
      </p>
    </CCardBody>
  </CCard>
</template>

<script setup></script>
