export const resIcon = [
  "410 410",
  `
  <path d="M11.9571 108.384C9.19687 102.385 4.53286 87.6538 2.81946 79.8912-2.22522 56.2502-1.27341 30.4922 5.48467 7.29231L7.57875 0.0589144 11.1005-0.20568 14.6223-0.470274 14.6223 55.9856C14.6223 87.1246 14.4319 112.53 14.2415 112.53 14.0512 112.53 13.0042 110.677 11.9571 108.384Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 270 113)"/>
  <path d="M8.24129 26.9964C3.43809 24.8335-0.368151 18.7953-0.368151 13.2979-0.368151 9.42265 2.98504 3.7451 6.70067 1.49208 10.7787-1.03138 17.485-0.941271 21.7444 1.58219 25.1882 3.7451 28.632 9.78319 28.632 13.8387 28.632 17.3533 25.4601 23.3915 22.56 25.5545 19.8414 27.4469 11.3225 28.3481 8.24129 26.9964Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 270 155)"/>
  <path d="M0.713074 58.6889C-0.114343 57.0022-0.665793 55.493-0.390068 55.3154-0.114343 55.049 94.5667 2.31563 99.0709-0.170186 102.288-1.85691 88.132 14.3004 79.859 21.9353 60.8309 39.3355 35.6439 53.0072 10.273 59.5767L2.18373 61.7074 0.713074 58.6889Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 310 212)"/>
  <path d="M25.0775 13.9577-0.321503 0.220207 13.6853-0.0423652 27.6921-0.304823 33.4816 3.89519C39.6445 8.27018 54.6785 21.4827 54.6785 22.4452 54.5851 22.7078 53.6514 24.0203 52.5308 25.3327L50.4765 27.6953 25.0775 13.9577Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 209 184)"/>
  <path d="M-0.377879 56.8676-0.377879 49.1031 5.19249 49.1031C9.92743 49.1031 11.2271 48.7501 13.084 46.8973 14.3836 45.7503 15.405 43.8091 15.405 42.7504 15.405 41.6033 12.2484 31.7213 8.44198 20.8688 4.63542 10.0163 1.47887 0.751935 1.47887 0.398948 1.47887-0.483345 15.4978-0.483345 16.9832 0.398948 17.8188 0.840152 17.8188 8.60459 17.0761 32.7802 16.519 50.2501 16.0549 64.632 15.962 64.7202 15.962 64.8084 14.1052 64.5437 11.9698 64.1908 9.83448 63.7496 6.21381 63.7496 3.89269 64.1026L-0.377879 64.7202-0.377879 56.8676Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 276 233)"/>
  <path d="M9.4314 26.7057C9.15588 26.0932 6.95137 19.7932 4.47134 12.6182L-0.121489-0.244318 21.5563-0.0693458 43.2339 0.193227 48.1023 14.0182 52.8787 27.7558 31.3847 27.7558C14.9427 27.7558 9.70704 27.4932 9.4314 26.7057Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 44 226)"/>
  <path d="M9.36198 26.7057C9.08646 26.0932 6.88195 19.7932 4.40192 12.6182L-0.190911-0.244318 21.4869-0.0693458 43.1645 0.193227 48.0329 14.0182 52.8093 27.7558 31.3153 27.7558C14.8733 27.7558 9.63762 27.4932 9.36198 26.7057Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 103 226)"/>
  <path d="M9.29256 26.7057C9.01704 26.0932 6.81253 19.7932 4.33249 12.6182L-0.260333-0.244318 21.4174-0.0693458 43.0951 0.193227 47.9634 14.0182 52.7398 27.7558 31.2459 27.7558C14.8039 27.7558 9.5682 27.4932 9.29256 26.7057Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 162 226)"/>
  <path d="M9.22312 26.7057C8.9476 26.0932 6.74309 19.7932 4.26306 12.6182L-0.329754-0.244318 21.348-0.0693458 43.0256 0.193227 47.8939 14.0182 52.6703 27.7558 31.1764 27.7558C14.7345 27.7558 9.49876 27.4932 9.22312 26.7057Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 221 226)"/>
  <path d="M9.62703 27.7224C9.34632 27.088 7.10021 20.5629 4.57338 13.1317L-0.106098-0.190259 21.9807-0.00903729 44.0673 0.262914 49.0275 14.5817 53.894 28.8099 31.9945 28.8099C15.2424 28.8099 9.90787 28.538 9.62703 27.7224Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 29 269)"/>
  <path d="M9.55628 27.7224C9.27557 27.088 7.02946 20.5629 4.50264 13.1317L-0.176829-0.190259 21.9099-0.00903729 43.9965 0.262914 48.9567 14.5817 53.8232 28.8099 31.9237 28.8099C15.1716 28.8099 9.83712 28.538 9.55628 27.7224Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 88 269)"/>
  <path d="M9.48555 27.7224C9.20483 27.088 6.95873 20.5629 4.43191 13.1317L-0.247561-0.190259 21.8392-0.00903729 43.9258 0.262914 48.886 14.5817 53.7525 28.8099 31.853 28.8099C15.1009 28.8099 9.76639 28.538 9.48555 27.7224Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 147 269)"/>
  <path d="M9.41484 27.7224C9.13412 27.088 6.88801 20.5629 4.36119 13.1317L-0.318294-0.190259 21.7685-0.00903729 43.8551 0.262914 48.8153 14.5817 53.6818 28.8099 31.7823 28.8099C15.0302 28.8099 9.69568 28.538 9.41484 27.7224Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 206 269)"/>
  <path d="M18.0687 121.738C17.7922 114.478 17.0547 91.3702 16.5937 70.387 16.0407 49.4923 15.3954 28.2434 15.1187 23.1968L14.5657 14.1659 7.28284 14.1659 0 14.1659 0 7.08297 0 0 29.5001 0 59 0 59 7.08297 59 14.1659 51.7173 14.1659 44.4344 14.1659 43.8813 23.2853C43.6048 28.2434 42.9594 49.4923 42.4063 70.387 41.9453 91.3702 41.2078 114.478 40.9313 121.738L38.3762 200 28.8995 192.78 20.2234 170.719C20.0391 166.292 18.2531 126.165 18.0687 121.738Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 255 410)"/>
  <path d="M9.4661 26.8271C9.19058 26.2145 6.98607 19.9145 4.50604 12.7395L-0.0867775-0.123078 21.591 0.0518947 43.2686 0.314468 48.1369 14.1395 52.9133 27.8771 31.4193 27.8771C14.9774 27.8771 9.74173 27.6145 9.4661 26.8271Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 15 311)"/>
  <path d="M9.39669 26.8271C9.12117 26.2145 6.91666 19.9145 4.43663 12.7395L-0.1562-0.123078 21.5216 0.0518947 43.1992 0.314468 48.0676 14.1395 52.844 27.8771 31.35 27.8771C14.908 27.8771 9.67233 27.6145 9.39669 26.8271Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 74 311)"/>
  <path d="M9.32727 26.8271C9.05175 26.2145 6.84724 19.9145 4.36721 12.7395L-0.225622-0.123078 21.4522 0.0518947 43.1298 0.314468 47.9982 14.1395 52.7745 27.8771 31.2806 27.8771C14.8386 27.8771 9.60291 27.6145 9.32727 26.8271Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 133 311)"/>
  <path d="M9.25785 26.8271C8.98233 26.2145 6.77782 19.9145 4.29778 12.7395L-0.295044-0.123078 21.3827 0.0518947 43.0604 0.314468 47.9287 14.1395 52.7051 27.8771 31.2111 27.8771C14.7692 27.8771 9.53349 27.6145 9.25785 26.8271Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 192 311)"/>
  <path d="M9.48347 26.8876C9.20795 26.2751 7.00344 19.975 4.5234 12.8L-0.0694221-0.0624572 21.6084 0.112515 43.286 0.375087 48.1544 14.2 52.9307 27.9376 31.4368 27.9376C14.9948 27.9376 9.75911 27.6751 9.48347 26.8876Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 0 353)"/>
  <path d="M9.41405 26.8876C9.13853 26.2751 6.93401 19.975 4.45398 12.8L-0.138844-0.0624572 21.5389 0.112515 43.2166 0.375087 48.0849 14.2 52.8613 27.9376 31.3673 27.9376C14.9254 27.9376 9.68969 27.6751 9.41405 26.8876Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 59 353)"/>
  <path d="M9.34463 26.8876C9.06911 26.2751 6.86459 19.975 4.38456 12.8L-0.208266-0.0624572 21.4695 0.112515 43.1472 0.375087 48.0155 14.2 52.7919 27.9376 31.2979 27.9376C14.856 27.9376 9.62027 27.6751 9.34463 26.8876Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 118 353)"/>
  <path d="M9.2752 26.8876C8.99968 26.2751 6.79517 19.975 4.31514 12.8L-0.277688-0.0624572 21.4001 0.112515 43.0777 0.375087 47.9461 14.2 52.7225 27.9376 31.2285 27.9376C14.7865 27.9376 9.55084 27.6751 9.2752 26.8876Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 177 353)"/>
  <path d="M14.4762 28.0084 14.4762 14.0083 7.14283 14.0083-0.19052 14.0083-0.19052 7.00829-0.19052 0.00826639 21.8095 0.00826639 43.8096 0.00826639 43.8096 7.00829 43.8096 14.0083 36.4762 14.0083 29.1429 14.0083 29.1429 28.0084 29.1429 42.0084 21.8095 42.0084 14.4762 42.0084 14.4762 28.0084Z" fill-rule="evenodd" transform="matrix(1 0 0 -1 108 410)"/>
`,
];
