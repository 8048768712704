/**
 * Takes an ISO formated date string (e.g. 2022-04-12T14:39:31.186664Z)
 * as django produces, and returns a localized format (e.g. 26/07/2022, 14:28:16)
 * @param {String} isoDate
 */
const formatedDate = (isoDate) => {
  var d = new Date(isoDate);
  return d.toLocaleString();
};

/**
 * Converts a given string to a prettier format by replacing certain characters with spaces,
 * capitalizing the first letter of each word, and joining them with spaces.
 * @param {string} name - The input string to be pretified.
 * @returns {string} A pretified version of the input string.
 */
const pretifyName = (name) => {
  return name
    .replace(/[-_.]/g, " ")
    .replace(/ +/g, " ")
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Util function that makes a new File with the correct filename. This is because
 * renaming is not supported for File objects
 * @param {File} file
 * @param {String} newFileName
 */
const renameFileInMemory = (file, newFileName) => {
  return new File([file], newFileName, {
    type: file.type,
    lastModified: file.lastModified,
  });
};

/**
 * Small util function to extract a file name from a django file url headers["content-disposition"]
 * e.g. from "inline; filename="Project1.ifc"" gets only "Project1.ifc"
 * Returns null if the filename is not found
 * @param {String} header
 */
const extractFilenameFromHeader = (header) => {
  const regex = /filename=["]?([^"]+)["]?/;
  const match = regex.exec(header);
  if (match && match.length > 1) {
    return match[1];
  }
  return null;
};

export {
  formatedDate,
  pretifyName,
  renameFileInMemory,
  extractFilenameFromHeader,
};
