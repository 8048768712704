// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const ianosLogo = [
  "756 662",
  `
<path d="M377.854 73.557C386.037 73.3104 395.708 75.592 406.421 83.2178L584.448 184.95C603.69 196.338 608.3 209.126 608.5 225.715L608.221 431.805 608.5 431.959C608.428 434.86 608.231 437.61 607.922 440.214L607.637 441.89 607.5 444.029C605.955 455.805 601.365 468.424 588.657 475.304L588.526 475.232 405.619 580.537C400.301 582.804 379.148 600.473 350.579 580.137L172.552 478.405C153.31 467.017 148.7 454.229 148.5 437.64L148.779 231.55 148.5 231.395C148.572 228.495 148.769 225.745 149.079 223.141L149.363 221.465 149.5 219.326C151.046 207.55 155.636 194.931 168.343 188.051L168.474 188.123 351.381 82.8178C354.705 81.4009 364.214 73.9678 377.854 73.557Z" stroke="#2F528F" stroke-width="1.33363" stroke-miterlimit="8" fill-rule="evenodd"/>
<path d="M484.746 128.569 486.932 129.16 584.447 184.792C603.689 196.162 608.3 208.93 608.5 225.492L608.475 244.012 608.286 243.998C473.743 237.829 396.528 292.57 368.02 310.167 310.648 351.968 239.772 394.382 175.532 414.53L148.5 421.726 148.517 409.472 170.118 403.338C274.282 370.3 317.7 304.625 329.935 290.729 365.704 241.601 399.267 180.891 484.746 128.569Z" stroke="#b5b5b5" stroke-width="1.33363" stroke-miterlimit="8"  fill-rule="evenodd" />
<path d="M583.752 243.646C591.711 243.579 599.892 243.727 608.297 244.114L608.485 244.128 608.5 318.157 587.695 312.955C536.89 301.325 470.689 295.652 376.16 340.858 306.417 376.331 280.55 406.666 149.5 433.732L149.5 422.042 175.752 415.035C239.96 394.841 310.802 352.331 368.147 310.433 394.86 293.899 464.362 244.644 583.752 243.646Z" stroke="#3d3d3d" stroke-width="1.33363" stroke-miterlimit="8"  fill-rule="evenodd" "/>
`,
];
