import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { axios } from "@/common/api.service";
import { useMainStore } from "@/stores/mainStore.js";

export const useBuildingStore = defineStore("buildingStore", () => {
  const mainStore = useMainStore();

  const baseUrl = "api/building/";

  const PilotName = ref(null);

  const systems = ref([]);

  const system = ref(null);

  const assets = ref({
    zones: [],
    constructions: [],
    layers: [],
    walls: [],
    slabs: [],
    tilteds: [],
    windows: [],
    doors: [],

    idealloads: [],
    hbchpwbs: [],
    hbchpwrs: [],
    hbchpwstbs: [],
    hbchpwstrs: [],
    hbchpwstbrs: [],
    hchpwrs: [],
    hchpwstrs: [],
    hhprchpwrs: [],
    hhprchpwstrs: [],

    pvs: [],
    pvbs: [],
    wts: [],
    wtbs: [],
    wtpvs: [],
    wtpvbs: [],

    runs: [],
  });

  const materials = ref([]);
  // special function giati petage 2 error toasts stin arxi
  function getMaterials() {
    mainStore.showSpinner();
    let endpoint = baseUrl + "materials/";

    return axios
      .get(endpoint)
      .then((response) => {
        materials.value = response.data;
      })
      .catch(() => {})
      .finally(() => mainStore.hideSpinner());
  }

  const irregularPlurals = {};
  function getPlural(name) {
    return irregularPlurals[name] ? irregularPlurals[name] : name + "s";
  }

  const dataCheck = computed(() => {
    var res = [{ building_info: { ...system.value } }];
    delete res[0].building_info.tmy_plots;

    res.push({ zones: assets.value.zones });
    res.push({ constructions: assets.value.constructions });
    res.push({ layers: assets.value.layers });
    res.push({ walls: assets.value.walls });
    res.push({ walls: assets.value.slabs });
    res.push({ walls: assets.value.tilted });
    res.push({ windows: assets.value.windows });
    res.push({ doors: assets.value.doors });
    res.push({
      systems: [
        ...assets.value.idealloads,
        ...assets.value.hbchpwbs,
        ...assets.value.hbchpwrs,
        ...assets.value.hbchpwstbs,
        ...assets.value.hbchpwstrs,
        ...assets.value.hbchpwstbrs,
        ...assets.value.hchpwrs,
        ...assets.value.hchpwstrs,
        ...assets.value.hhprchpwrs,
        ...assets.value.hhprchpwstrs,

        ...assets.value.pvs,
        ...assets.value.pvbs,
        ...assets.value.wts,
        ...assets.value.wtbs,
        ...assets.value.wtpvs,
        ...assets.value.wtpvbs,
      ],
    });
    return res;
  });

  /**
   * Loads all system assets and runs
   * @param {number} systemId The system id. If null, the currently
   * loaded system refreshes.
   */
  function loadSystem(systemId) {
    if (!systemId) systemId = system.value.id;
    mainStore.showSpinner();
    let endpoint = baseUrl + "systems/" + systemId + "/";
    return axios
      .get(endpoint)
      .then((resp) => {
        system.value = resp.data;
      })
      .catch((error) => mainStore.addFailToast(error))
      .finally(async () => {
        Object.keys(assets.value).forEach(async (name) => {
          await retrieveItems(name);
        });
        mainStore.hideSpinner();
      });
  }

  /**
   * Checks if component is an asset, i.e. belongs to a system
   * @param {number} name The component's name
   */
  function isAsset(name) {
    return name in assets.value ? true : false;
  }

  /**
   * Creates an item, i.e. performs a POST request.
   * Automatically includes the systemId as foreign key, if required (asset)
   * @param {number} name The name of the component type, i.e loads, lines
   * @param {object} data Form data to be sent
   */
  function createItem(name, data) {
    mainStore.showSpinner();
    let endpoint = baseUrl + name + "/";
    if (isAsset(name)) data.system = system.value.id;
    return axios
      .post(endpoint, data)
      .then((response) => {
        if (isAsset(name)) {
          assets.value[name].push(response.data);
        } else {
          eval(name).value.push(response.data);
        }
      })
      .catch((error) => mainStore.addFailToast(error))
      .finally(() => mainStore.hideSpinner());
  }

  /**
   * Retrieves all items of a kind, i.e. performs a GET request.
   * Automatically includes the systemId as foreign key, if required (asset)
   * @param {number} name Items name, i.e loads, lines
   */
  function retrieveItems(name) {
    mainStore.showSpinner();
    let endpoint;
    if (isAsset(name)) {
      endpoint = baseUrl + name + "/?system=" + system.value.id;
    } else {
      endpoint = baseUrl + name + "/";
    }
    return axios
      .get(endpoint)
      .then((response) => {
        if (isAsset(name)) {
          assets.value[name] = response.data;
        } else {
          eval(name).value = response.data;
        }
      })
      .catch((error) => mainStore.addFailToast(error))
      .finally(() => mainStore.hideSpinner());
  }

  /**
   * Updates an item, i.e. performs a PATCH request.
   * Automatically includes the systemId as foreign key, if required (asset)
   * @param {number} name The name of the component type, i.e loads, lines
   * @param {number} id The id of the particular item
   * @param {object} data Form data to be sent
   * @param {boolean} [showSuccess=true] Pop success toast
   */
  function updateItem(name, id, data, showSuccess = true) {
    mainStore.showSpinner();
    let endpoint = baseUrl + name + "/" + id + "/";
    if (isAsset(name)) data.system = system.value.id;

    return axios
      .patch(endpoint, data)
      .then((response) => {
        if (isAsset(name)) {
          let foundIndex = assets.value[name].findIndex(
            (x) => x.id == response.data.id
          );
          assets.value[name][foundIndex] = response.data;
        } else {
          let foundIndex = eval(name).value.findIndex(
            (x) => x.id == response.data.id
          );
          eval(name).value[foundIndex] = response.data;
        }

        if (showSuccess) {
          mainStore.addToast({
            header: "Saved",
            color: "light",
            text: "Item has been successfully updated.",
          });
        }
      })
      .catch((error) => mainStore.addFailToast(error))
      .finally(() => mainStore.hideSpinner());
  }

  /**
   * Deletes an item, i.e. performs a DELETE request.
   * Automatically includes the systemId as foreign key, if required (asset)
   * @param {number} name The name of the component type, i.e loads, lines
   * @param {number} id The id of the particular item
   */
  function deleteItem(name, id) {
    mainStore.showSpinner();
    let endpoint = baseUrl + name + "/" + id + "/";
    return axios
      .delete(endpoint)
      .then(() => {
        if (isAsset(name)) {
          let foundIndex = assets.value[name].findIndex((x) => x.id == id);
          // pop item
          assets.value[name].splice(foundIndex, 1);
        } else {
          let foundIndex = eval(name).value.findIndex((x) => x.id == id);
          // pop item
          eval(name).value.splice(foundIndex, 1);
        }
      })
      .catch((error) => mainStore.addFailToast(error))
      .finally(() => mainStore.hideSpinner());
  }

  return {
    baseUrl,
    PilotName,
    system,
    systems,
    assets,
    materials,
    getMaterials,
    getPlural,
    dataCheck,
    loadSystem,
    createItem,
    retrieveItems,
    updateItem,
    deleteItem,
  };
});
