<template>
  <div class="form-group row my-1">
    <label v-if="label" :class="'col-' + labelCol + ' col-form-label'">{{
      label
    }}</label>
    <div class="col align-self-center">
      <BaseSelect
        v-if="type == 'select'"
        :model-value="modelValue"
        v-bind="$attrs"
        @update:model-value="updateValue"
      />
      <textarea
        v-else-if="type == 'textarea'"
        :value="modelValue"
        class="form-control"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <input
        v-else-if="type == 'file'"
        class="form-control"
        type="file"
        v-bind="$attrs"
        @change="$emit('update:modelValue', $event.target.files[0])"
      />
      <input
        v-else
        :value="modelValue"
        class="form-control"
        step="any"
        :type="type"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <CFormText v-if="helperText">
        {{ helperText }}
      </CFormText>
    </div>
  </div>
</template>

<script setup>
/**
 * The main component for form elements.
 * The component supports:
 * - all input types, i.e. number, text, email etc
 * - textarea
 * - select
 * Any type specific attributes are passed through $attrs (i.e. not declared
 * as props)
 */
import BaseSelect from "./BaseSelect.vue";

defineProps({
  modelValue: undefined,
  type: {
    type: String,
    required: true,
  },
  /**
   * Optional label to be added inline.
   */
  label: {
    type: String,
    default: null,
  },
  /**
   * Optional helper text for the field.
   */
  helperText: {
    type: String,
    default: null,
  },
  /**
   * Optional arguiment for the size of label/formItem ratio. Defaults to 4/12.
   */
  labelCol: {
    type: String,
    default: "4",
  },
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = (value) => {
  emit("update:modelValue", value);
};
</script>
