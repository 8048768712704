<template>
  <CFooter :fixed="false" style="flex-direction: row-reverse">
    <div>
      <a href="https://www.cperi.certh.gr/en/" target="_blank">CERTH/CPERI</a>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }} | All rights reserved.</span
      >
    </div>
  </CFooter>
</template>

<script setup></script>
