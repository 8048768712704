<template>
  <CHeader position="sticky" class="mb-4">
    <div class="d-flex align-items-center">
      <CHeaderToggler class="ps-1" @click="mainStore.toggleSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <TheHeaderBreadcrumb />
    </div>

    <CSpinner v-show="mainStore.spinner" color="info" />

    <div class="d-flex align-items-center mr-3">
      <div v-show="route.path.includes('/building/')">
        {{ getTxt }}
      </div>

      <CNavLink href="#">
        <CIcon class="mx-2" icon="cil-bell" size="lg" />
      </CNavLink>

      <TheHeaderDropdownAccnt />

      <CToaster placement="top-end">
        <CToast
          v-for="(toast, index) in mainStore.toasts"
          :key="index"
          :color="toast.color"
          :autohide="true"
          :visible="true"
        >
          <CToastHeader close-button>
            <span class="me-auto fw-bold">{{ toast.header }}</span>
          </CToastHeader>
          <CToastBody class="fw-bold">
            {{ toast.text }}
          </CToastBody>
        </CToast>
      </CToaster>
    </div>
  </CHeader>
</template>

<script setup>
import { computed } from "vue";
import { useMainStore } from "@/stores/mainStore.js";
import { useBuildingStore } from "@/stores/buildingStore.js";

import { useRoute } from "vue-router";
import TheHeaderBreadcrumb from "@/components/layout/TheHeaderBreadcrumb.vue";
import TheHeaderDropdownAccnt from "@/components/layout/TheHeaderDropdownAccnt.vue";

const mainStore = useMainStore();
const buildingStore = useBuildingStore();
const route = useRoute();

const getTxt = computed(() => {
  if (buildingStore.system) {
    return "Selected Building System: " + buildingStore.system.id;
  } else {
    return undefined;
  }
});
</script>

<style>
.toast {
  flex-basis: auto;
}
</style>
