<template>
  <v-select
    ref="refElement"
    class="fixDaSelect"
    :model-value="modelValue"
    :reduce="(option) => option.value"
    :options="options"
    :placeholder="placeholder"
    :searchable="searchable"
    :append-to-body="appendToBody"
    :calculate-position="withPopper"
    @update:model-value="updateValue"
  />
</template>

<script setup>
/**
 * A wrapper component of v-select. This was needed for 2 reasons:
 * 1. to set the prefered defaults
 * 2. to involve the dropdown/dropup decision functionality. The component checks
 *    if it has space beneath and if not it expands upwards. This is implemented in
 *    withPopper(). A check was required for the component not to be inside a modal
 *    as the modal was closing at selecting an option.
 */
import { ref, onMounted } from "vue";
import { createPopper } from "@popperjs/core";

defineProps({
  modelValue: undefined,
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: "Please select...",
  },
  searchable: {
    type: Boolean,
    default: false,
  },
});

const refElement = ref(null);

const appendToBody = ref(true);

onMounted(() => {
  // appendToBody should be false if inside a Modal
  var element = refElement.value.$el;

  var nodes = [];
  nodes.push(element);
  while (element.parentNode) {
    nodes.unshift(element.parentNode);
    element = element.parentNode;
  }
  nodes.forEach((node) => {
    if (node.classList) {
      if (node.classList.contains("modal")) appendToBody.value = false;
    }
  });
});

const emit = defineEmits(["update:modelValue"]);

const placement = ref("bottom");

const updateValue = (value) => {
  emit("update:modelValue", value);
};

const withPopper = (dropdownList, component, { width }) => {
  // https://vue-select.org/guide/positioning.html#popper-js-integration
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */
  dropdownList.style.width = width;

  /**
   * Here we position the dropdownList relative to the $refs.toggle Element.
   *
   * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
   * the dropdownList overlap by 1 pixel.
   *
   * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
   * wrapper so that we can set some styles for when the dropdown is placed
   * above.
   */
  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: placement.value,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  /**
   * To prevent memory leaks Popper needs to be destroyed.
   * If you return function, it will be called just before dropdown is removed from DOM.
   */
  return () => popper.destroy();
};
</script>

<style lang="scss">
.fixDaSelect {
  background-color: var(--cui-input-bg, #fff);
  // z-index: 2;
}

.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

:root {
  --vs-border-color: var(--cui-input-border-color, #b1b7c1);
  --vs-line-height: 1.5;
  // --vs-search-input-placeholder-color =?
  --vs-dropdown-z-index: 1055; // to --cui-modal-zindex = 1055
}

.vs__search::placeholder {
  opacity: 0.6;
}
</style>
