<template>
  <TheSidebar />
  <div class="wrapper d-flex flex-column min-vh-100 bg-light">
    <TheHeader />
    <div class="body d-flex flex-column flex-grow-1 px-3">
      <TheStepper
        v-if="
          $route.path.includes('/building') &&
          !$route.path.includes('/system-selection')
        "
      />
      <router-view />
    </div>
    <TheFooter />
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import TheHeader from "@/components/layout/TheHeader.vue";
import TheSidebar from "@/components/layout/TheSidebar.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import TheStepper from "@/components/layout/TheStepper.vue";

import jwt_decode from "jwt-decode";
import { useRoute, useRouter } from "vue-router";
import { axios } from "@/common/api.service";
import { useBuildingStore } from "@/stores/buildingStore.js";

export default defineComponent({
  components: { TheHeader, TheSidebar, TheFooter, TheStepper },

  async setup() {
    function checkCookie(name) {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.indexOf(name + "=") === 0) {
          return true; // Cookie with the specified name exists
        }
      }
      return false; // Cookie with the specified name does not exist
    }

    // var cookie1Exists = checkCookie("csrftoken");
    // var cookie2Exists = checkCookie("sessionid");
    // if (cookie1Exists) return;

    const route = useRoute();
    const router = useRouter();
    // console.log("route", route);

    const query = computed(() => route.query);
    // console.log("query", query);
    // console.log("query.value", query.value);
    // console.log("einai adeio?", Object.keys(query.value).length === 0);
    // console.log("einai gemato?", !(Object.keys(query.value).length === 0));

    console.log("prin mpei sto id");

    if (!(Object.keys(query.value).length === 0)) {
      localStorage.setItem("token", query.value.token);
      const decoded = jwt_decode(query.value.token);
      // console.log(query.value.token);
      // console.log(decoded);
      // console.log(decoded.email);

      const endpoint =
        "https://rinno.iti.gr/rinnoApi/users/getUserBasicInfo/" + decoded.id;
      const config = {
        headers: { Authorization: `Bearer ${query.value.token}` },
        baseURL: "/",
      };
      const resp = await axios.get(endpoint, config);
      console.log("meta to request");
      console.log(resp);

      const store = useBuildingStore();
      store.PilotName = resp.data[0].pilot;
      // console.log("template", store.PilotName);

      await axios
        .post("api/token/force/", { email: decoded.email })
        .then(() => {
          // console.log("response", response);
          router.push("/");
        })
        .catch((error) => {
          console.log(error);
          router.push("/login");
        });
    } else {
      // console.log("mpika sto else");
      router.push("/login");
    }
  },
});
</script>
