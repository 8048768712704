// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const incubeLogo = [
  "410 410",
  `
  <path d="M79.5 107.26 153.508 63.5 208.5 96.3204 186.4 107.781 186.657 133.308 154.022 150.5 79.5 107.26Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.85098"/>
  <path d="M79.5 106.5 80.5355 157.832 131.941 188.863 131.453 193.5 151.782 181.66 152.5 149.619 79.5 106.5Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.6"/>
  <path d="M130.5 188.909 69.7917 151.5 6.49998 188.13 68.7583 224.5 130.5 188.909Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.85098"/>
  <path d="M6.49998 188.5C6.80491 212.885 7.70887 237.27 8.0138 261.655L70.5 296.5 68.7534 224.205 6.49998 188.5Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.6"/>
  <path d="M71.7299 295.5 97.2272 281.18 96.9713 212.743 130.5 193.999C130.497 192.499 130.494 190.999 130.492 189.5L70.5 224.139 71.7299 295.5Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.25098"/>
  <path d="M97.5 312.145 182.988 362.5 183.5 262.048 98.0119 213.5 97.5 312.145Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.6"/>
  <path d="M184.5 261.137 269.5 212.5 268.72 313.12 184.76 361.5 184.5 261.137Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.25098"/>
  <path d="M187.5 108.442 295.87 48.5 404.5 110.243 295.352 172.5 187.5 108.442Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.85098"/>
  <path d="M98.5 212.85 182.28 164.5 269.5 210.521 184 261.5 98.5 212.85Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.85098"/>
  <path d="M296.533 296.5 404.5 233.197 403.467 111.5 295.5 172.736 296.533 296.5Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.25098"/>
  <path d="M268.564 281.495 295.5 297.5 294.217 171.522 188.27 107.5 187.5 167.683 269.284 210.469C269.044 234.144 268.804 257.82 268.564 281.495Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.6"/>
  <path d="M153.353 150.849 152.5 182.5 182.2 164.641 187.011 167.144C187.174 155.596 187.337 144.048 187.5 132.5L153.353 150.849Z" stroke="#2F528F" stroke-width="1.33442" stroke-miterlimit="8" fill="#FFFFFF" fill-rule="evenodd" fill-opacity="0.25098"/>
  `,
];
