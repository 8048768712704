<template>
  <CModal
    :visible="modelValue"
    keyboard
    @close="emit('update:modelValue', false)"
  >
    <CModalHeader>
      <CModalTitle :style="{ color: 'var(--cui-' + color + ')' }">{{
        title
      }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <form
        id="daform"
        @submit.prevent="
          () => {
            emit('update:modelValue', false);
            emit('submit');
          }
        "
      >
        <slot /></form
    ></CModalBody>
    <CModalFooter>
      <CButton
        color="secondary"
        variant="outline"
        @click="emit('update:modelValue', false)"
      >
        Close
      </CButton>
      <CButton :color="color" type="submit" variant="outline" form="daform"
        >Submit</CButton
      >
    </CModalFooter>
  </CModal>
</template>

<script setup>
/**
 * A Modal component with no internal state. An event is emitted on modelValue change.
 * The component can receive content via a slot.
 */
defineProps({
  modelValue: Boolean,
  color: {
    type: String,
    default: "primary",
  },
  title: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "submit"]);
</script>
