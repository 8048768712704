<template>
  <CTable>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell
          v-for="field in fields"
          :key="field.key"
          scope="col"
          >{{ field.label || pretifyName(field.key) }}</CTableHeaderCell
        >
      </CTableRow>
    </CTableHead>
    <CTableBody>
      <CTableRow v-for="(row, indexX) in items" :key="indexX">
        <template v-for="(field, indexY) in fields" :key="indexY">
          <CTableDataCell v-if="field.key === 'created_at'">{{
            formatedDate(row[field.key])
          }}</CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'deleteBtn'">
            <BaseButton
              size="sm"
              color="danger"
              icon="cil-trash"
              @click="emit('deleteBtnClicked', row)"
            />
          </CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'editBtn'">
            <BaseButton
              size="sm"
              color="warning"
              icon="edit-icon"
              @click="emit('editBtnClicked', row)"
            />
          </CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'selectBtn'">
            <BaseButton
              size="sm"
              icon="cil-task"
              @click="emit('selectBtnClicked', row, field.label)"
            />
          </CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'downloadBtn'">
            <BaseButton
              size="sm"
              color="info"
              icon="cil-cloud-download"
              @click="emit('downloadBtnClicked', row)"
            />
          </CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'uploadBtn'">
            <BaseButton
              size="sm"
              color="info"
              icon="cil-cloud-upload"
              @click="emit('uploadBtnClicked', row)"
            />
          </CTableDataCell>
          <CTableDataCell v-else-if="field.key === 'shareBtn'">
            <BaseButton
              size="sm"
              color="warning"
              icon="cil-share-boxed"
              @click="emit('shareBtnClicked', row)"
            />
          </CTableDataCell>
          <template v-else>
            <slot :name="field.key" :item="row">
              <CTableDataCell>{{ row[field.key] }}</CTableDataCell>
            </slot>
          </template>
        </template>
      </CTableRow>
      <CTableRow v-if="!items.length">
        <CTableDataCell :colspan="fields.length">
          <div class="text-center my-5">
            <h2>
              No items
              <CIcon width="30" content="cil-ban" class="text-danger" />
            </h2>
          </div>
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>
</template>

<script setup>
import { formatedDate, pretifyName } from "@/common/utils.js";

/**
 * A Table component for data display.
 * Buttons are conditionally displayed depending on whether specific keys
 * are included as fields, i.e.: deleteBtn, editBtn etc
 * The component does not include any logic.
 * Rather it emmits coresponding events for: edit, delete, download, upload and select.
 */
defineProps({
  items: {
    type: [Array, null],
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits([
  "deleteBtnClicked",
  "editBtnClicked",
  "selectBtnClicked",
  "downloadBtnClicked",
  "uploadBtnClicked",
  "shareBtnClicked",
]);
</script>
