<template>
  <CCard>
    <CCardBody>
      <h1 class="display-4">Relevant references</h1>
      <p class="lead">of our team on the energy management field.</p>
      <hr class="my-4" />
      <ul style="list-style-type: square">
        <li>
          Apostolopoulos V, Mamounakis I, Seitaridis A, Tagkoulis N, Kourkoumpas
          D-S, Iliadis P, et al. Αn integrated life cycle assessment and life
          cycle costing approach towards sustainable building renovation via a
          dynamic online tool. Applied Energy 2023;334:120710.
          <a
            href="https://doi.org/10.1016/j.apenergy.2023.120710"
            target="_blank"
            >https://doi.org/10.1016/j.apenergy.2023.120710</a
          >.
        </li>
        <li>
          Bellos E, Iliadis P, Papalexis C, Rotas R, Mamounakis I, Sougkakis V,
          et al. Holistic renovation of a multi-family building in Greece based
          on dynamic simulation analysis. Journal of Cleaner Production
          2022;381:135202.
          <a
            href="https://doi.org/10.1016/j.jclepro.2022.135202"
            target="_blank"
            >https://doi.org/10.1016/j.jclepro.2022.135202</a
          >.
        </li>
        <li>
          Bellos E, Iliadis P, Papalexis C, Rotas R, Nikolopoulos N,
          Kosmatopoulos E, et al. Dynamic investigation of centralized and
          decentralized storage systems for a district heating network. Journal
          of Energy Storage 2022;56:106072.
          <a href="https://doi.org/10.1016/j.est.2022.106072" target="_blank"
            >https://doi.org/10.1016/j.est.2022.106072</a
          >.
        </li>
        <li>
          Rotas R, Iliadis P, Nikolopoulos N, Tomboulides A, Kosmatopoulos E.
          Dynamic Simulation and Performance Enhancement Analysis of a Renewable
          Driven Trigeneration System. Energies 2022;15:3688.
          <a href="https://doi.org/10.3390/en15103688" target="_blank"
            >https://doi.org/10.3390/en15103688</a
          >.
        </li>
        <li>
          Seitaridis A, Mamounakis I, Tagkoulis N, Iliadis P, Bellos E,
          Papalexis C, et al. An Innovative Software Platform for Efficient
          Energy, Environmental and Cost Planning in Buildings Retrofitting. In:
          Maglogiannis I, Iliadis L, Macintyre J, Cortez P, editors. Artificial
          Intelligence Applications and Innovations. AIAI 2022 IFIP WG 12.5
          International Workshops, vol. 652, Cham: Springer International
          Publishing; 2022, p. 217–28.
          <a href="https://doi.org/10.1007/978-3-031-08341-9_18" target="_blank"
            >https://doi.org/10.1007/978-3-031-08341-9_18</a
          >.
        </li>
        <li>
          Papatsounis AG, Botsaris PN, Rotas R, Kanellia Z, Iliadis P. Operation
          assessment of a hybrid solar-biomass energy system with absorption
          refrigeration scenarios. Energy Sources, Part A: Recovery,
          Utilization, and Environmental Effects 2022;44:700–17.
          <a
            href="https://doi.org/10.1080/15567036.2022.2049929"
            target="_blank"
            >https://doi.org/10.1080/15567036.2022.2049929</a
          >.
        </li>
        <li>
          Genov E, Petridis S, Iliadis P, Nikopoulos N, Coosemans T, Messagie M,
          et al. Short-Term Load Forecasting in a microgrid environment:
          Investigating the series-specific and cross-learning forecasting
          methods. Journal of Physics: Conference Series, vol. 2042, 2021, p.
          012035.
          <a
            href="https://doi.org/10.1088/1742-6596/2042/1/012035"
            target="_blank"
            >https://doi.org/10.1088/1742-6596/2042/1/012035</a
          >.
        </li>
        <li>
          Violidakis I, Atsonios K, Iliadis P, Nikolopoulos N. Dynamic modeling
          and energy analysis of renewable heating and electricity systems at
          residential buildings using phase change material based heat storage
          technologies. Journal of Energy Storage 2020;32:101942.
          <a href="https://doi.org/10.1016/j.est.2020.101942" target="_blank"
            >https://doi.org/10.1016/j.est.2020.101942</a
          >.
        </li>
        <li>
          Ntomalis S, Iliadis P, Atsonios K, Nesiadis A, Nikolopoulos N,
          Grammelis P. Dynamic Modeling and Simulation of Non-Interconnected
          Systems under High-RES Penetration: The Madeira Island Case. Energies
          2020;13:5786.
          <a href="https://doi.org/10.3390/en13215786" target="_blank"
            >https://doi.org/10.3390/en13215786</a
          >.
        </li>
        <li>
          Iliadis P, Ntomalis S, Atsonios K, Nesiadis A, Nikolopoulos N,
          Grammelis P. Energy management and techno‐economic assessment of a
          predictive battery storage system applying a load levelling
          operational strategy in island systems. Int J Energy Res
          2020;45:2709–27.
          <a href="https://doi.org/10.1002/er.5963" target="_blank"
            >https://doi.org/10.1002/er.5963</a
          >.
        </li>
        <li>
          Chapaloglou S, Nesiadis A, Iliadis P, Atsonios K, Nikolopoulos N,
          Grammelis P, et al. Smart energy management algorithm for load
          smoothing and peak shaving based on load forecasting of an island’s
          power system. Applied Energy 2019;238:627–42.
          <a
            href="https://doi.org/10.1016/j.apenergy.2019.01.102"
            target="_blank"
            >https://doi.org/10.1016/j.apenergy.2019.01.102</a
          >.
        </li>
        <li>
          Iliadis P, Domalis S, Nesiadis A, Atsonios K, Chapaloglou S,
          Nikolopoulos N, et al. Advanced energy management system based on PV
          and load forecasting for load smoothing and optimized peak shaving of
          islanded power systems. In: Sorce A, Tucker D, Sayma A, editors. E3S
          Web of Conferences, vol. 113, 2019, p. 03001.
          <a href="https://doi.org/10.1051/e3sconf/201911303001" target="_blank"
            >https://doi.org/10.1051/e3sconf/201911303001</a
          >.
        </li>
      </ul>
    </CCardBody>
  </CCard>
</template>

<script setup></script>
